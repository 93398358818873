import { useEffect, useState } from 'react';
import useSelector from '../redux/typedHooks';

export const usePuzzelEUWA = () => {
  const [euwa, setEuwa] = useState<EUWA | undefined>();

  const configId = useSelector((state) => state.application.puzzelConfigurationId);
  const customerKey = useSelector((state) => state.application.puzzelCustomerKey);

  useEffect(() => {
    const initEUWA = async () => {
      const { EUWA } = await import('@puzzel/euwa-wrapper');
      if (configId && customerKey) {
        const euwaInstance = new EUWA({ configId, customerKey }, {});
        setEuwa(euwaInstance);
      }
    };
    setTimeout(() => {
      initEUWA();
    }, 3000);
  }, [configId, customerKey]);

  return euwa;
};
