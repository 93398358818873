import { useCallback, useEffect, useMemo, useState } from 'react';
import useSelector from '../redux/typedHooks';

export const usePuzzelChat = (euwa?: EUWA) => {
  const [chat, setChat] = useState<ApplicationBridge | undefined>();
  const [shouldGetChatVariables, setShouldGetChatVariables] = useState(false);

  const customerInformation = useSelector((state) => state.myMembership.customerInformation.data.customer);

  const getChatVariables = useMemo(() => {
    if (customerInformation && chat && chat?.api?.updateSystemVariables && shouldGetChatVariables) {
      return () => {
        chat?.api?.updateSystemVariables({
          enteredFormName: customerInformation.fullName,
          enteredChatId: customerInformation.email,
        });
        chat?.api.updateVariables({
          memberNumber: customerInformation.customerNumber,
        });
      };
    }
    return null;
  }, [customerInformation, chat, shouldGetChatVariables]);

  const initChat = useCallback(async () => {
    const { EUWA } = await import('@puzzel/euwa-wrapper');
    if (euwa && EUWA) {
      const returnedChat = await euwa.getApplication(EUWA.APPS.CHAT);
      if (returnedChat) {
        returnedChat?.subscribe('chatWindowShown', () => {
          setShouldGetChatVariables(true);
        });
        setChat(returnedChat);
      }
    }
  }, [euwa]);

  useEffect(() => {
    if (!chat) {
      initChat().then();
    }
  }, [chat, initChat]);

  // Update system variables on request
  useEffect(() => {
    if (getChatVariables) {
      getChatVariables();
      setShouldGetChatVariables(false);
    }
  }, [getChatVariables, setShouldGetChatVariables]);
};
